.redux-toastr {
  .rrt-title {
    font-size: 18px !important;
    font-weight: 500 !important;
    line-height: 26px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    margin-bottom: 0 !important;
  }
  .rrt-text {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0px !important;
    text-align: left !important;
    color: #120e02 !important;
  }

  .top-right {
    width: unset !important;
    max-width: 500px !important;
  }

  .toastr {
    background-color: #ffffff !important;
    min-height: 50px;
    padding: 14px 18px 16px 14px;
    box-shadow: none !important;
    border-radius: 0px !important;
    opacity: 1 !important;

    > div {
      display: flex;
    }
  }

  .toastr-icon {
    width: 20px !important;
    height: 20px !important;
    margin-top: 0 !important;
  }

  .rrt-success {
    border: 1px solid #009f4d;
    .rrt-title {
      color: #009f4d !important;
    }
    .toastr-icon {
      fill: #009f4d !important;
    }
  }
  .rrt-warning {
    border: 1px solid #b54708;
    .rrt-title {
      color: #b54708 !important;
    }
    .toastr-icon {
      fill: #b54708 !important;
    }
  }

  .rrt-info {
    border: 1px solid $primary;
    .rrt-title {
      color: $primary !important;
    }
    .toastr-icon {
      fill: $primary !important;
    }
  }

  .rrt-error {
    border: 1px solid #bc3c3c;

    .rrt-title {
      color: #bc3c3c !important;
    }
    .toastr-icon {
      fill: #bc3c3c !important;
    }
  }

  .close-toastr {
    color: #9e9e9e !important;
    opacity: 1 !important;
    width: unset !important;
    height: unset !important;
    position: static !important;
    top: unset !important;
    right: unset !important;
    background-color: transparent !important;
    font-size: 16px !important;
    outline: none !important;
    opacity: 1 !important;

    span {
      position: static !important;
    }
  }

  .rrt-middle-container {
    padding: 0 !important;
    flex: 1 !important;
    margin-left: 14px !important;
    margin-right: 50px;
    position: relative !important;
  }
  .rrt-left-container {
    position: relative !important;
    width: 20px !important;
    overflow: visible !important;
    .rrt-holder {
      width: 20px !important;
      height: 20px !important;
      position: relative !important;
      top: unset !important;
      margin-top: 0 !important;
      left: unset !important;
      line-height: 18px !important;
    }
  }
  .rrt-right-container {
    width: unset !important;
  }
}
