//
// type.scss
// Extended from Bootstrap
//

//
// Bootstrap Overrides =====================================
//

h1,
.h1 {
  margin-bottom: $headings-margin-bottom;
  font-size: 1.5rem;

  @include media-breakpoint-up(md) {
    font-size: $h1-font-size;
  }
}

h2,
.h2 {
  margin-bottom: $headings-margin-bottom;
}

h3,
.h3 {
  margin-bottom: ($headings-margin-bottom * 0.75);
}

h4,
.h4 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}

h5,
.h5 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}

h6,
.h6 {
  margin-bottom: ($headings-margin-bottom * 0.5);
}

// Links
// Headings

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  letter-spacing: $headings-letter-spacing;
  > a {
    color: inherit;
  }
}

// Type display classes

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: $display-letter-spacing;
}

h6.text-uppercase {
  letter-spacing: 0.08em;
}

// Bold, strong

b,
strong {
  font-weight: $font-weight-bold;
}

// Links, buttons
//
// Removes focus outline

a,
button {
  &:focus {
    outline: none !important;
  }
}

//
// Theme =====================================
//

// Include Cerebri Sans

// @font-face {
//   font-family: 'Cerebri Sans';
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.eot');
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.eot?#iefix')
//       format('embedded-opentype'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.woff') format('woff'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-regular.ttf') format('truetype');
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.eot');
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.eot?#iefix')
//       format('embedded-opentype'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.woff') format('woff'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-medium.ttf') format('truetype');
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'Cerebri Sans';
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.eot');
//   src: url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.eot?#iefix')
//       format('embedded-opentype'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.woff') format('woff'),
//     url('#{$path-to-fonts}/cerebrisans/cerebrisans-semibold.ttf') format('truetype');
//   font-weight: 600;
//   font-style: normal;
// }

// Include Feather icons

@font-face {
  font-family: 'Feather';
  src: url('#{$path-to-fonts}/feather/Feather.ttf?sdxovp') format('truetype'),
    url('#{$path-to-fonts}/feather/Feather.woff?sdxovp') format('woff'),
    url('#{$path-to-fonts}/feather/Feather.svg?sdxovp#Feather') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'MetaOT';
  src: local('MetaOT'), url('../../fonts/metaOT/MetaOT.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'MetaOTBold';
  src: local('MetaOT-Bold'), url('../../fonts/metaOT/MetaOT-Bold.ttf') format('truetype');
  font-weight: bold;
}
